import { CircularProgress, ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

const Home = lazy(() => import('../pages/Home'))

export enum ROUTES {
    HOME = '/calls',
    CONFIGURATIONS = '/calls/configurations',
    REPORTING = '/calls/reporting',
    ENGAGEMENT = '/calls/reporting/engagement',
    ENGAGEMENT_ADVANCED_METRICS = '/calls/reporting/engagement/advanced-metrics',
    PERFORMANCE = '/calls/reporting/performance',
    AGENT_OVERVIEW_REPORTING = '/calls/reporting/agent/:id',
    QUEUE_COMPARISON_PERFORMANCE = '/calls/reporting/performance/queue-comparison',
    QUEUE_SUBSET_PERFORMANCE = '/calls/reporting/performance/queue-subset'
}

const Router: React.FC = () => {
    const theme = useTheme()
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<CircularProgress />}>
                    <Switch>
                        <ProtectedRoute path={ROUTES.HOME} component={Home} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
